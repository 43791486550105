// Barra principal
.fdx-menu.lateral {
  float: right;
  clear: both;
  margin-top: 50px;

  // TABLET
  @include mq(m) {
    margin-top: 38px;
  }

  // MOBILE
  @include mq(s) {
    display: none;
  }

  li {
    float: left;
    position: relative;
  }

  // Links geral
  a {
    display: inline-block;
    width: 100%;
    font-family: $fonte_geral;
    font-size: 14px;
    line-height: 100%;
    font-weight: 300;
    text-transform: uppercase;
    transition: .3s ease-out;

    // TABLET
    @include mq(m) {
      font-size: 10px;
    }

    &:hover {
      color: $cor_primaria;
    }
  }
}

// 1 Level
.lateral .fdx-menu-1-level {
  float: left;
  width: 100%;

  // Item
  >li {
    line-height: 0;
    padding: 0 13px;

    // TABLET
    @include mq(m) {
      padding: 0 5px;
    }

    // Remove o padding do primeiro item
    &:first-child {
      padding-left: 0px;
    }

    // Remove o padding do último item
    &:last-child {
      padding-right: 0px;
    }

    // Link
    >a {
      position: relative;
      z-index: 2;
      color: $cor_fonte_escura;
      text-transform: uppercase;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 51%;
        right: 51%;
        bottom: -10px;
        background: $cor_primaria;
        height: 2px;
        -webkit-transition-property: left, right;
        transition-property: left, right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }

      &:hover:before,
      &:focus:before,
      &:active:before {
        left: 0;
        right: 0;
      }
    }
  }
}

// 2 Level
.lateral .fdx-menu-2-level {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  width: 250px;
  padding: 10px 0;
  background: $cor_primaria;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0) translate3d(0, -10px, 0);
  transform-origin: top left;
  transition: .3s ease;

  // Ponta
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -9px;
    left: 73px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 10px 15px;
    border-color: transparent transparent $cor_primaria transparent;
  }

  &::after {
    content: '';
    display: none;
    position: absolute;
    z-index: -1;
    top: -25px;
    left: 0;
    width: 100%;
    height: 25px;
  }

  // Item
  li {
    float: left;
    width: 100%;

    // Link
    a {
      padding: 10px 20px;
      color: #FFF;
      border-left: 2px solid transparent;

      &:hover {
        border-left: 6px solid $cor_primaria;
        color: #FFF;
      }
    }
  }

  &.ponta1 {

    // Ponta
    &::before {
      left: 55px;
    }

  }
}

.lateral .fdx-menu-1-level>li:hover>.fdx-menu-2-level {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translate3d(0, 0, 0);

  &::after {
    display: block;
  }
}

// 3 Level
.lateral .fdx-menu-3-level {
  position: absolute;
  top: -10px;
  right: -250px;
  width: 250px;
  padding: 10px 0;
  background: $cor_secundaria;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  opacity: 0;
  visibility: hidden;
  transform: translate(-20px, 0);
  transition: .3s ease;
}

.lateral .fdx-menu-2-level>li:hover>.fdx-menu-3-level {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
