// Box-sizing
*,
*::after,
*::before {
  box-sizing: border-box;
}

// Remove outline de foco do firefox e outros browsers
:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

// Cor do texto selecionado
::selection {
  background: rgba($cor_primaria, .5);
}

::-moz-selection {
  background: rgba($cor_primaria, .5);
}

// Remove o fundo azul de itens clicados no chrome para android
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

// Body
body {
  background: #FFF;
  font-family: $fonte_geral;
  font-size: 16px;
  line-height: 1.4;
  color: $cor_fonte_clara;
  padding-top: 90px;

  // TABLET
  @include mq(m) {
    padding-top: 67px
  }

  // MOBILE
  @include mq(s) {
    padding-top: 86px;
  }
}

// Fix imagens
img {
  display: block;
  max-width: 100%;
}

// Seções
.secao {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  // MOBILE
  @include mq(s) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  // Corrige o bug do scroll horizontal nas animações
  .container {
    overflow: hidden;
  }
}

// ------------------------------------
//  ATENDIMENTO (LATERAL)
// ------------------------------------
.side-contatos {
  position: fixed;
  z-index: $z_index_side;
  top: 30%;
  right: 15px;
  width: 65px;
  background: transparent;
  padding: 20px 10px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  //box-shadow: 0px 3px 5px rgba(0,0,0,0.3); 
  visibility: visible;
  opacity: 1;
  transition: .3s ease-out;
  -ms-transform: translate(0px, 0px);
  /* IE 9 */
  -webkit-transform: translate(0px, 0px);
  /* Safari */
  transform: translate(0px, 0px);

  // Item
  li {
    float: left;
    width: 100%;

    a {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 58px;
      border-radius: 50%;
      background: rgba($cor_whatsapp, .8);
      text-align: center;
      margin: 5px 0px;
      transition: .3s ease-out;

      &:hover {
        background: rgba($cor_whatsapp, 1);
      }

      i {
        color: #FFF;
        font-size: 28px;
        transition: .3s ease-out;
      }
    }
  }

  &.oculto {
    visibility: hidden;
    opacity: 0;
    -ms-transform: translate(65px, 0px);
    /* IE 9 */
    -webkit-transform: translate(65px, 0px);
    /* Safari */
    transform: translate(65px, 0px);
  }

}
