// Header principal
.header {
  position: fixed;
  z-index: $z_index_header;
  float: left;
  top: 0;
  width: 100%;
  background: #FFF;
  padding: 5px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .26);

  // MOBILE
  @include mq(s) {
    padding-top: 35px;
    padding-bottom: 10px;
    text-align: center;
  }
}

// Conteúdo (Container)
.header .container {
  position: static;
}

.header-content {
  position: relative;
  padding: 0 10px;

  // MOBILE
  @include mq(s) {
    position: static;
  }
}

// Logo
.header-logo {
  display: block;
  float: left;
  max-width: 135px;

  // TABLET
  @include mq(m) {
    max-width: 97px;
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 70px;
  }
}

// Direita
.header-direita {
  float: right;

  // MOBILE
  @include mq(s) {
    height: 0px;
  }
}

// Botões
.header-botoes {
  float: right;
  text-align: right;
  margin-left: 50px;

  // MOBILE
  @include mq(s) {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    display: none;
  }

  .btn {
    margin-left: 10px;

    // MOBILE
    @include mq(s) {
      font-size: 12px;
      margin: 5px;
      padding: 10px 15px;
    }
  }
}

// Telefones
.header-telefones {
  text-align: right;
  background: $cor_primaria;
  float: left;
  padding: 5px 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  top: -6px;
  right: 7px;

  // MOBILE
  @include mq(s) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
    background: $cor_fundo2;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  // Item
  a {
    display: inline-block;
    margin-left: 20px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    color: #FFF;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 14px;
      margin: 0 5px;
      color: $cor_fonte_escura;
    }

    i {
      margin-right: 5px;
      color: #FFF;

      // MOBILE
      @include mq(s) {
        color: $cor_primaria;
      }
    }
  }
}
