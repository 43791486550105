// Vídeo caixa
.video-caixa {
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  background: #020C24;

  // Vídeo
  iframe {
    width: 100%;
    height: 300px;

    // TABLET
    @include mq(m) {
      height: 250px;
    }

    // MOBILE
    @include mq(s) {
      height: 170px;
    }
  }

  // Título
  h2 {
    float: left;
    width: 100%;
    padding: 12px 20px;
    color: #FFF;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
    position: relative;

    &:after {
      background: linear-gradient(90deg, rgba(64, 105, 136, 1) 23%, rgba(86, 146, 195, 1) 45%);
      position: absolute;
      content: '';
      height: 3px;
      right: 0;
      left: 0;
      top: -6px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 16px;
      padding: 10px;
    }

    // TABLET
    @include mq(m) {
      font-size: 16px;
      padding: 10px;
    }

    i {
      margin-right: 15px;
    }
  }
}

.pagina-caixa {
  background: $cor_fundo2;

  h2 {
    color: $cor_fonte_escura;
    font-size: 16px;
  }

  i {
    color: $cor_primaria;
  }
}
