.transformacao-detalhe-texto {
  text-align: center;

}

.segura-servico-foto {
  margin-top: 20px;
}

// Foto
.foto-home {
  position: relative;
  float: left;
  border: 2px solid #FFF;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 25%;
  height: 250px;
  overflow: hidden;
  border-radius: 5px;

  // TABLET
  @include mq(m) {
    height: 200px;
  }

  // MOBILE
  @include mq(s) {
    width: 50%;
    height: 150px;
  }

  figure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: .5s ease-out;
  }

  &:hover figure {
    transform: scale(1.1);
  }
}
