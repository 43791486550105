.home-fotos {
  padding-top: 0px;
}

// Carrosel
.carrosel-fotos {
  //margin-top: px;

  // Bloco FOTOS
  .servico {
    display: block;
    width: 100%;
    margin: 5px 10px;
  }
}
